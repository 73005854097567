import * as React from 'react';

import {Account, User} from '../../users/user.class';
import {AccountFinderViewProps, filterVisible} from './AccountFinderView';
import {SortableHeader} from './SortableHeader';
import {sortItems} from '../../ui/sort-filter.functions';
import {TableCards} from '../../ui/Tables/TableCards';
import {useState} from 'react';

interface AccountFinderAccountListProps extends AccountFinderViewProps {
    accountKeys: string[];
    accountOnlyMode: boolean;
    selectAccount: (accountNum: string, contactID: string, returnUrlParam?: string) => void;
    user: User;
}
export const AccountFinderAccountList = ({account, accountKeys, accountOnlyMode, selectAccount, user}: AccountFinderAccountListProps) => {
    const [accounts, setAccounts] = useState(account.accounts?.filter(filterVisible()) ?? []);
    const [sortField, setSortField] = useState('');
    const [sortDec, setSortDec] = useState(true);
    const changeSort = (newSortField: string, newSortDec: boolean) => {
        if (!account.accounts) {
            return;
        }
        setSortField(newSortField);
        setSortDec(newSortDec);
        const filteredAccounts = account.accounts.filter(filterVisible());
        let sortedAccounts = sortItems(filteredAccounts, newSortField);
        if (!newSortDec) {
            sortedAccounts = sortedAccounts.reverse();
        }
        setAccounts(sortedAccounts);
    };
    // Do not render if no accounts
    if (accounts.length === 0) return null;

    /**
     * Template
     */
    return (
        <div className={accountKeys.indexOf(account.account) >= 0 ? `d-none` : `table-responsive`}>
            <TableCards
                columns={[
                    {
                        title: 'Accounts',
                        fieldName: 'account',
                        overrideTH: (key: number, className: string) => (
                            <SortableHeader
                                key={key}
                                className={className}
                                fieldDesc="Accounts"
                                fieldName="account"
                                changeSort={changeSort}
                                currentField={sortField}
                                sortDec={sortDec}
                            />
                        ),
                    },
                    {
                        title: 'Site ID',
                        customFormatter: (childAccount: Account) => (
                            <span className="tw-capitalize">{childAccount.custAcct?.toLowerCase()}</span>
                        ),
                        overrideTH: (key: number, className: string) => (
                            <SortableHeader
                                key={key}
                                className={className}
                                fieldDesc="Site ID"
                                fieldName="custAcct"
                                changeSort={changeSort}
                                currentField={sortField}
                                sortDec={sortDec}
                            />
                        ),
                    },
                    {
                        title: 'Attention',
                        maskPII: true,
                        customFormatter: (childAccount: Account) => (
                            <span className="tw-capitalize">
                                {childAccount.attn?.toLowerCase()}
                                {accountOnlyMode && childAccount.orders?.orderCount > 0 && (
                                    <>
                                        <br />
                                        {childAccount.orders.orderCount} orders pending
                                    </>
                                )}
                                {!accountOnlyMode && childAccount.orders?.orderCount > 0 && (
                                    <>
                                        <br />
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                selectAccount(childAccount.account, childAccount.contact, `/orders`);
                                            }}
                                        >
                                            {childAccount.orders.orderCount} orders pending
                                        </a>
                                    </>
                                )}
                                {!accountOnlyMode && childAccount.orders?.pendCount > 0 && (
                                    <>
                                        <br />
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                selectAccount(childAccount.account, childAccount.contact, `/orders/approval`);
                                            }}
                                        >
                                            {childAccount.orders.pendCount} orders awaiting approval
                                        </a>
                                    </>
                                )}
                            </span>
                        ),
                        overrideTH: (key: number, className: string) => (
                            <SortableHeader
                                key={key}
                                className={className}
                                fieldDesc="Attention"
                                fieldName="attn"
                                changeSort={changeSort}
                                currentField={sortField}
                                sortDec={sortDec}
                            />
                        ),
                    },
                    {
                        title: 'Name',
                        maskPII: true,
                        customFormatter: (childAccount: Account) => (
                            <span className="tw-capitalize">{childAccount.name?.toLowerCase()}</span>
                        ),
                        overrideTH: (key: number, className: string) => (
                            <SortableHeader
                                key={key}
                                className={className}
                                fieldDesc="Name"
                                fieldName="name"
                                changeSort={changeSort}
                                currentField={sortField}
                                sortDec={sortDec}
                            />
                        ),
                    },
                    {
                        title: 'City',
                        customFormatter: (childAccount: Account) => (
                            <span className="tw-capitalize">{childAccount.city?.toLowerCase()}</span>
                        ),
                        overrideTH: (key: number, className: string) => (
                            <SortableHeader
                                key={key}
                                className={className}
                                fieldDesc="City"
                                fieldName="city"
                                changeSort={changeSort}
                                currentField={sortField}
                                sortDec={sortDec}
                            />
                        ),
                    },
                    {
                        title: 'State',
                        fieldName: 'st',
                        overrideTH: (key: number, className: string) => (
                            <SortableHeader
                                key={key}
                                className={className}
                                fieldDesc="State"
                                fieldName="st"
                                changeSort={changeSort}
                                currentField={sortField}
                                sortDec={sortDec}
                            />
                        ),
                    },
                    {
                        title: '',
                        rightAlign: true,
                        customFormatter: (childAccount: Account) => (
                            <div className="tw-text-right">
                                {user.activeAccount === childAccount.account && <>Logged In</>}
                                {user.activeAccount !== childAccount.account && (
                                    <button
                                        className="btn btn-outline-secondary text-nowrap col-sm-12 col-auto mr-0 mr-md-1"
                                        onClick={() => selectAccount(childAccount.account, childAccount.contact)}
                                    >
                                        Log In
                                    </button>
                                )}
                            </div>
                        ),
                    },
                ]}
                data={accounts.filter((childAccount: Account) => !childAccount.hidden)}
            />
        </div>
    );
};
